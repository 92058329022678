<template>
  <div class="content">
    <div class="size_title">申请书</div>
    <div class="size_weight">湖北利道科技投资有限公司工会委员会：</div>
    <div>因湖北利道科技投资有限公司工会委员会与申请人签订了 《利道工会会员分红权赠子合同书》，申请人享有利道工会
      <span class="sp-stock">{{dataList.sales.shares}}
      </span>权数的分红权，现申请人与
      <span class="sp-stock">{{dataList.trade_user.union_info.username}}</span>
      买方（公民身份号码为：<span class="sp-stock">{{dataList.trade_user.union_info.identity}}
      </span>（以下简称“受让人”）就利道工会
      <span class="sp-stock">{{dataList.sales.shares}}
      </span>权数的分红权转让事宜签订了协议书（详见《居间服务协议书》。现申请人特向湖北利道科技投资有限公司工会委员会强烈申请将湖北利道科技投资有限公司工会委员会免费赠子给申请人的利道工会<span class="sp-stock">{{dataList.sales.shares}}</span>权数的分红权转让登记至受让人名下，由湖北利道科技投资有限公司工会委员会直接扣除本次申请人向受让人转让的分红权，由受让人与湖北利道科技投资有限公司工会委员会就申请人转让给受让人的分红权签订《利道工会会员分红权赠子合同书》，受让人受让以上分红权后，分红权相应的权益及法律责任均由受让人享有及承担，申请人不再享有，由此产生的法律责任及风险均由申请人及受让人自行承担。</div>
    <div>申请人及受让人转让的分红权的每权数的价格系由申请人及受让人双方自主协商确定，故以上价格可能存在波动或以上分红权可能并不存在价值，故申请人在选择出让以上分红权时己经充分考虑以上法律风险，由此产生的盈亏由申请人自负。受让人在选择受让以上分红权时己经充分考虑以上法律风险，由此产生的盈亏由受让人自负。湖北利道科技投资有限公司工会委员会对其赠手给申请人的分红权并未作任何价格保证或承诺，对此受让人清楚，且自愿承担相应法律风险。</div>
    <div>以上申请书系申请人及受让人的真实意思表示，由此产生的一切法
      律责任均由申请人及受让人承担。</div>
    <div>特此申请。</div>
    <div class="size_weight">敬礼</div>
    <div>
      <div class="namebox" style="margin-top:0">
        <div>申请人（签字）:</div><img class="nameImg" v-if="(dataList.sales_info.signature||img1)" :src="dataList.sales_info.signature||img1" />
      </div>
      <div class="namebox">
        <div>受让人（签字）：</div><img class="nameImg" v-if="(dataList.trade_info.signature||img)" :src="dataList.trade_info.signature||img" />
      </div>
    </div>

    <div class="namebox" style="text-align: right">签订日期： 年 月 日</div>

  </div>
</template>
<script>
import { dealDetails_api, getConfig_api } from "@/api/deal";
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      tableData: {
        user: {
          union_info: {}
        },
        trade_user: {
          union_info: {}
        }
      },
      tableConfig: {},
      img: "",
      img1:"",
      queryList: {},
      dataList: {
        trade_info:{},
        sales_info:{},
        sales: {},
        salesUser: {
          union_info: {
            bank: {}
          }
        }
      },
      dataTime: ""
    };
  },
  async created() {
    document.title = "申请书";
    this.getHome();
    this.getConfig();
    this.queryList = this.$route.query;
    this.getDetails(this.queryList.id);
    if(this.queryList.id){
      await this.getDetails(this.queryList.id);
    }
    if(this.queryList.idx){
      await this.getDetails(this.queryList.idx);
    }
    if (localStorage.getItem("signature")) {
      this.img = localStorage.getItem("signature");
    }
    if (localStorage.getItem("signature1")) {
      this.img1 = localStorage.getItem("signature1");
    }
    this.dataTime = new Date().getFullYear();
  },
  methods: {
    async getDetails(id) {
      let res = await apiCommon.putOrder_api(id);
      if (res.code == 0) {
        console.log(res.data);
        this.dataList = res.data;
        this.dataList.sales.price =
          res.data.sales.shares_amount *
          (
            (res.data.sales.tax_rate * 100 +
              res.data.sales.commission_rate * 100) /
            100
          ).toFixed(2);
        this.dataList.sales.priceText = this.moneyToCapital(
          this.dataList.sales.price
        );
        this.dataList.sales.priceText1 = this.moneyToCapital(
          res.data.sales.shares_amount *
            ((res.data.sales.commission_rate * 100) / 100)
        );
        this.dataList.sales.rateText = this.moneyToCapital(
          res.data.sales.tax_rate * 100 + res.data.sales.commission_rate * 100
        );
        console.log(this.dataList);
        if(!this.dataList.trade_info){
          this.dataList.trade_info = {
            signature:'',
            union_info:{}
          }
        }
        if(!this.dataList.sales_info){
            this.dataList.sales_info = {
              signature:''
            }
        }
        this.$forceUpdate();
        // if(this.tableData.shipments.length>0){
        //   this.logistics()
        // }
      }
    },
    moneyToCapital(n) {
      if (n == 0) {
        return "零";
      }
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return "";
      var unit = "千百拾亿千百拾万千百拾元角分",
        str = "";
      n += "00";
      var p = n.indexOf(".");
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++)
        str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
      return str
        .replace(/零(千|百|拾|角)/g, "零")
        .replace(/(零)+/g, "零")
        .replace(/零(万|亿|元)/g, "$1")
        .replace(/(亿)万|壹(拾)/g, "$1$2")
        .replace(/^元零?|零分/g, "")
        .replace(/元$/g, "元整");
    },
    getHome() {
      dealDetails_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    getConfig() {
      let data = {
        config: [
          "shares_sales_payee",
          "shares_sales_payee_account",
          "shares_sales_payee_address",
          "shares_commission_rate",
          "shares_tax_rate"
        ]
      };
      getConfig_api(data).then(res => {
        console.log(res.data);
        this.tableConfig = res.data;
        console.log(
          this.queryList.price *
            this.queryList.num *
            ((this.tableConfig.shares_commission_rate * 100 +
              this.tableConfig.shares_tax_rate * 100) /
              100),
          111
        );
        this.tableConfig.priceText = this.moneyToCapital(
          this.queryList.price *
            this.queryList.num *
            ((this.tableConfig.shares_commission_rate * 100 +
              this.tableConfig.shares_tax_rate * 100) /
              100)
        );
        this.tableConfig.priceText1 = this.moneyToCapital(
          this.queryList.price *
            this.queryList.num *
            ((this.tableConfig.shares_commission_rate * 100 +
              this.tableConfig.shares_tax_rate * 100) /
              100)
        );
      });
    }
  }
};
</script>
<style scoped>
.content {
  font-size: 25px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.size_weight {
  font-weight: bold;
  margin-top: 20px;
  text-indent: 0;
}
.size_title {
  font-size: 35px;
  text-align: center;
  margin: 30px;
}
.sp-stock {
  border-bottom: 1px solid #000;
  height: 30px;
  /* padding-left: 20px; */
  box-sizing: border-box;
  text-align: center;
  font-size: 30px;
  margin: 0 5px;
  text-align: center;
  font-weight: bold;
}
div {
  margin: 20px 0;
  line-height: 40px;
  font-size: 30px;
  text-indent: 2em;
}
.namebox {
  margin: 180px 0;
  position: relative;
  display: flex;
}
.namebox img {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -130px;
  left: 500px;
}
.namebox .nameImg {
  width: 100px;
  /* height: 50px; */
  top: -100px;
  left: 400px;
  transform: rotate(-90deg);
}
</style>